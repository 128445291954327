import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const manageChildren = [
  {
    path: "stations",
    name: "stations",
    // meta 元数据
    meta: { title: "充电站" },
    component: () => import("@/views/manage/stations"),
  },
  {
    path: "order",
    name: "order",
    meta: { title: "订单管理" },
    component: () => import("@/views/manage/order"),
  },
  {
    path: "fund",
    name: "fund",
    meta: { title: "资金管理" },
    component: () => import("@/views/manage/fund"),
  },
  {
    path: "stations/chargePiles",
    name: "chargePiles",
    meta: { title: "充电桩" },
    component: () => import("@/views/manage/stations/chargePiles"),
  },
  {
    path: "stations/detail",
    name: "chargePileDetail",
    meta: { title: "充电桩详情" },
    component: () => import("@/views/manage/stations/detail"),
  },
  {
    path: "order/detail",
    name: "orderDetail",
    meta: { title: "订单详情" },
    component: () => import("@/views/manage/order/detail.vue"),
  },
];

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    meta: { title: "登录" },
    component: () => import("@/views/login/index"),
  },
  {
    path: "/bigdata",
    name: "bigdata",
    meta: { title: "Bigdata" },
    component: () => import("@/views/bigdata/index"),
  },
  {
    path: "/manage",
    name: "manage",
    meta: { title: "后台管理" },
    component: () => import("@/views/manage"),
    redirect: "/manage/stations",
    children: manageChildren,
  },
];

const router = new VueRouter({
  routes,
});

// 修改网页页签标题
router.beforeEach((to, from, next) => {
  to.meta.title && (document.title = to.meta.title);
  next();
});

export default router;

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    let token = localStorage.getItem("Authorization");
    if (token === null || token === "") {
      next("/login");
    } else {
      next();
    }
  }
});
