import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {     //类似data  便于接下来的mutation要调用
    isFold: false,
    // 存储token
    Authorization: localStorage.getItem("Authorization")
      ? localStorage.getItem("Authorization")
      : "",
  },
  getters: {},
  mutations: {   //定义全局要用的方法
    CHANGE(state) {
      state.isFold = !state.isFold;      //这个是给背景左移右移的标志位
    },
    // 修改token，并将token存入localStorage  ，在使用的时候主要起到修改的过程
    changeLogin(state, user) {
      state.Authorization = user.Authorization;
      localStorage.setItem("Authorization", user.Authorization);
    },
  },
  actions: {      //定义全局全局的异步方法
    changeFold(context, isFold) {     //提交CHANGE方法
      context.commit("CHANGE", isFold);
    },
  },
  modules: {},
});
